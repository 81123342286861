import React from 'react';
import Page from '../components/Page';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Helmet } from 'react-helmet';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function Poem({ pageContext }) {
  const { title, publishedDate, authorName, content } = pageContext;
  const date = new Date(publishedDate);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;
  const subtitle = (
    <>
      <span>{`By ${authorName}`}</span>
      <br />
      <span>{formattedDate}</span>
    </>
  );
  const htmlContent = documentToHtmlString(content)
    .replace(/<p><\/p>/g, '<br />')
    .replace(/<p>&nbsp;<\/p>/g, '<br />');
  const metaTitle = `${title} - Poetry by Andrew`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="og:title" content={metaTitle} />
        <meta name="twitter:title" content={metaTitle} />
      </Helmet>

      <Page title={title} subtitle={subtitle} content={htmlContent} />
    </>
  );
}

export default Poem;
